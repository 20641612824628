import {
  Alert,
  Button,
  ButtonBorder,
  Div,
  Dropdown,
  HeaderBack,
  Label,
} from 'components';
import {Ecolors, urlApp} from 'constant';
import md5 from 'md5';
import React, {useEffect, useRef, useState} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {useDispatch} from 'react-redux';
import {loadListProduct} from 'reducer/portal';
import {apiPortal} from 'services';
import {useAppSelector} from 'store/hooks';
import {toUnixTime} from 'utils';
import {getStoreData, getStoreToken} from 'utils/storage';
import ItemCalendar from '../Dashboard/ItemCalendar';

const valuedata = [
  {
    content: 'Fee For Subscription Transaction',
    id: 'fee1',
    option: `product,scheme,fromdate,todate`,
    url: `tracking/export-buy-order-by-source`,
  },
  {
    content: 'Detail For Subscription Transaction',
    id: 'fee2',
    option: `product,scheme,fromdate,todate`,
    url: `tracking/export-detail-order-by-source`,
  },
  {
    content: 'Transaction Balance',
    id: 'fee3',
    option: `product,todate`,
    url: `tracking/export-balance-by-source`,
  },
  {
    content: 'Aum',
    id: 'fee4',
    option: `month,year`,
    url: `tracking/export-aum-by-source`,
  },
  {
    content: 'Fee Return',
    id: 'fee5',
    option: `product,scheme,year`,
    url: `tracking/export-return-fee-by-source`,
  },
];

function ManagementFee() {
  const [itemFocus, setItemFocus] = useState<any>(valuedata[0]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const listProduct = useAppSelector(state => state.portal.listProduct);
  // state

  const [listScheme, setListScheme] = useState<Array<any>>([]);
  //
  const [product, setProduct] = useState<any>(null);
  const [scheme, setScheme] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);
  //
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);

  useEffect(() => {
    dispatch(loadListProduct({}));
    return () => {};
  }, []);

  useEffect(() => {
    if (ref1.current) {
      ref1.current.clearData();
    }
    if (ref2.current) {
      ref2.current.clearData();
    }
    if (ref3.current) {
      ref3.current.clearData();
    }
    if (ref4.current) {
      ref4.current.clearData();
    }
    if (ref5.current) {
      ref5.current.clearData();
    }
    Promise.all([
      setProduct(null),
      setScheme(null),
      // setFromDate(null),
      // setToDate(null),
      // setMonth(null),
      // setYear(null),
    ]);
    return () => {};
  }, [itemFocus]);

  const switchObjBody = () => {
    // Log('itemsma', itemFocus);
    // Log('itemsma', {
    //   monthStats: `${month?.value}/${year?.value}`,
    //   month: month,
    //   year: year,
    // });
    // return;
    switch (itemFocus.id) {
      case 'fee1':
        return {
          fromDate: toUnixTime(fromDate),
          toDate: toUnixTime(toDate),
          programCode: scheme?.tradeCode || '',
        };
      case 'fee2':
        return {
          fromDate: toUnixTime(fromDate),
          toDate: toUnixTime(toDate),
          programCode: scheme?.tradeCode || '',
        };
      case 'fee3':
        return {
          productCode: product?.code || '',
          toDate: toUnixTime(toDate),
        };
      case 'fee4':
        return {
          monthStats: `${month?.value}/${year?.value}`,
          // yearStats: year?.value,
        };
      case 'fee5':
        return {
          yearStats: year?.value,
          programCode: scheme?.tradeCode || '',
        };
      default:
        return {};
    }
  };

  const downloadConfirm = async () => {
    try {
      setLoading(true);
      const url = `${urlApp.APIURL}api/${itemFocus.url}`;
      const token = await getStoreToken();
      const email = await getStoreData('email');
      const phone = await getStoreData('phone');
      const refCode = await getStoreData('refCode');
      const agentCode = await getStoreData('agentCode');
      const refKey = await getStoreData('refKey');
      // const email = 'manld1@fincorp.com.vn';
      // const phone = `0765000655`;
      // const refCode = `7292d7660bb1a5b7e214ca297ba04fe9fb3de293`;
      // const agentCode = `924`;
      // const refKey = `4a84351eab64d151a34df1573ec9d411b4c6163d`;
      var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        email: email,
        phone: phone,
        refCode: refCode,
        agentCode: agentCode,
        Authorization: token
          ? `Bearer ${token}`
          : `Bearer ${md5(`${email}${phone}${refCode}${agentCode}${refKey}`)}`,
      };
      var body = switchObjBody();
      const data = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });
      const name = data.headers
        ?.get(`content-disposition`)
        .replace('attachment; filename="', '')
        .replace(/"/g, '');
      data.blob().then(t => {
        let dataurl = window.URL.createObjectURL(t);
        let a = document.createElement('a');
        a.href = dataurl;
        a.download = name;
        a.click();
      });
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        multilanguage: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeProduct = async (e: any) => {
    if (e.id == 0) {
      setProduct(e);
      setScheme(null);
      setListScheme([
        {
          id: 'id',
          name: 'Chọn tất cả',
          nameEn: 'Chọn tất cả',
          tradeCode: '',
        },
      ]);
      return;
    }
    setProduct(e);
    setScheme(null);
    const res = await apiPortal.loadProductDetals({
      id: e.id,
    });
    if (res.status == 200) {
      setListScheme([
        {
          id: 'id',
          name: 'Chọn tất cả',
          nameEn: 'Chọn tất cả',
          tradeCode: '',
        },
        ...res.data,
      ]);
    }
  };

  return (
    <Div flex={1}>
      <HeaderBack title={`managementfee.managementfee`} />

      <Div>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          <Div flexDirection={'row'} alignItems={'center'} paddingVertical={10}>
            {valuedata.map((item: any, index: number) => {
              const isFocus = itemFocus.id == item.id;
              return (
                <Button
                  onPress={() => {
                    setItemFocus(item);
                  }}
                  key={index}
                  paddingHorizontal={20}
                  borderBottomWidth={2}
                  paddingVertical={10}
                  borderBottomColor={
                    isFocus ? Ecolors.mainColor : Ecolors.bordercolor
                  }>
                  <Label fontWeight={'bold'} size={14} multilanguage={false}>
                    {item.content}
                  </Label>
                </Button>
              );
            })}
          </Div>
        </ScrollView>
        <Div paddingHorizontal={16} paddingTop={18} flex={1}>
          {itemFocus.option.includes('product') && (
            <Dropdown
              multiline={true}
              marginTop={6}
              paddingHorizontal={0}
              initData={listProduct}
              value={product}
              multilanguage={true}
              content={`createordermodal.chonsanpham`}
              isActive={!!listProduct.length}
              onChange={onChangeProduct}
            />
          )}
          {itemFocus.option.includes('scheme') && (
            <Dropdown
              multiline={true}
              marginTop={10}
              paddingHorizontal={0}
              initData={listScheme}
              value={scheme}
              multilanguage={true}
              content={`createordermodal.chonchuongtrinh`}
              isActive={
                !!product && !!listProduct.length && !!listScheme.length
              }
              onChange={e => {
                setScheme(e);
              }}
            />
          )}
          {itemFocus.option.includes('fromdate') &&
            itemFocus.option.includes('todate') && (
              <Div
                flexDirection="row"
                alignItems="center"
                marginTop={10}
                justifyContent="space-between">
                <ItemCalendar
                  ref={ref1}
                  title={'Từ ngày'}
                  onChangeTime={(t: any) => setFromDate(t)}
                />
                <ItemCalendar
                  ref={ref2}
                  title={'Đến ngày'}
                  onChangeTime={(t: any) => setToDate(t)}
                />
              </Div>
            )}

          {!itemFocus.option.includes('fromdate') &&
            itemFocus.option.includes('todate') && (
              <>
                <Div height={10} />
                <ItemCalendar
                  ref={ref3}
                  type="all"
                  width={343}
                  onChangeTime={(t: any) => setToDate(t)}
                  height={48}
                  title={'Đến ngày'}
                />
              </>
            )}
          {itemFocus.option.includes('month') && (
            <>
              <Div height={10} />
              <ItemCalendar
                ref={ref4}
                type="month"
                width={343}
                onChangeTime={(t: any) => setMonth(t?.month)}
                height={48}
                title={'Chọn tháng'}
              />
            </>
          )}
          {itemFocus.option.includes('year') && (
            <>
              <Div height={10} />
              <ItemCalendar
                ref={ref5}
                type="year"
                width={343}
                height={48}
                onChangeTime={(t: any) => setYear(t?.year)}
                title={'Chọn năm'}
              />
            </>
          )}

          <ButtonBorder
            onPress={() => {
              downloadConfirm();
            }}
            loading={loading}
            width={343}
            height={48}
            marginTop={15}
            title={'managementfee.export'}
          />
        </Div>
      </Div>
    </Div>
  );
}

export default React.memo(ManagementFee);

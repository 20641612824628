import {createSelector} from 'reselect';
import {RootState} from './../index';

const productInfo = (state: RootState) => state.portal.productInfo;
const loadingListInvestment = (state: RootState) =>
  state.portal.loadingListInvestment;
const loadingListAUMFee = (state: RootState) => state.portal.loadingListAUMFee;
const loadingAmountDashboard = (state: RootState) =>
  state.portal.loadingAmountDashboard;
const isLoadingCurrentUser = (state: RootState) =>
  state.authen.isLoadingCurrentUser;
const loadingListTransactionFee = (state: RootState) =>
  state.portal.loadingListTransactionFee;

export const getDataBuyChartPortal = createSelector(
  [productInfo],
  productInfo => {
    const unit = 1000000000;
    const data = productInfo
      ?.slice(0, productInfo.length - 1)
      ?.map((item: any, index: number) => {
        return {
          x: item.productCode,
          y: item.totalSubscriptionAmount / unit,
        };
      });
    return data || [];
  },
);
export const getDataSellChartPortal = createSelector(
  [productInfo],
  productInfo => {
    const unit = 1000000000;
    const data = productInfo
      ?.slice(0, productInfo.length - 1)
      ?.map((item: any, index: number) => {
        return {
          x: item.productCode,
          y: item.totalRedemAmount / unit,
        };
      });
    return data || [];
  },
);
export const getDataNetChartPortal = createSelector(
  [productInfo],
  productInfo => {
    const unit = 1000000000;
    const data = productInfo
      ?.slice(0, productInfo.length - 1)
      ?.map((item: any, index: number) => {
        return {
          x: item.productCode,
          y: item.totalNetAmount / unit,
        };
      });
    return data || [];
  },
);

export const getLoadingContainer = createSelector(
  [
    loadingListInvestment,
    loadingListAUMFee,
    loadingAmountDashboard,
    loadingListTransactionFee,
    isLoadingCurrentUser,
  ],
  (
    loadingListInvestment,
    loadingListAUMFee,
    loadingAmountDashboard,
    loadingListTransactionFee,
    isLoadingCurrentUser,
  ) => {
    return (
      loadingListInvestment ||
      loadingListAUMFee ||
      loadingAmountDashboard ||
      loadingListTransactionFee ||
      isLoadingCurrentUser
    );
  },
);

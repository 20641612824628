import {Div, HeaderBack} from 'components';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadListInvestment} from 'reducer/portal';
import ListInvestor from './ListInvestor';
import SearchInput from './SearchInput';

function Investor() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadListInvestment({}));
  }, []);
  return (
    <Div screen={true}>
      <HeaderBack title={`investor.nhadautu`} />
      <SearchInput />
      <ListInvestor />
    </Div>
  );
}

export default React.memo(Investor);

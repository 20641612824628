import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import React from 'react';
import FooterApp from './FooterApp';
import {translateXOptionsScreen} from './optionsNavigate';

import {Ecolors} from 'constant';
import {useAppSelector} from 'store/hooks';
import AUMFee from '../screens/Portal/AUMFee';
import Dashboard from '../screens/Portal/Dashboard';
import Investor from '../screens/Portal/Investor';
import ManagementFee from '../screens/Portal/ManagementFee';
import TransactionsFee from '../screens/Portal/TransactionsFee';

const Tab = createBottomTabNavigator();
const ROLE_SOURCE = [
  {
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    name: 'Investor',
    component: Investor,
  },
  {
    name: 'ManagementFee',
    component: ManagementFee,
  },
];

const ROLE_BRANCH = [
  {
    name: 'Investor',
    component: Investor,
  },
  {
    name: 'AUMFee',
    component: AUMFee,
  },
  {
    name: 'TransactionsFee',
    component: TransactionsFee,
  },
];
const ROLE_RM = [
  {
    name: 'Investor',
    component: Investor,
  },
  {
    name: 'AUMFee',
    component: AUMFee,
  },
  {
    name: 'TransactionsFee',
    component: TransactionsFee,
  },
];

function TabContainer() {
  const currentUser = useAppSelector(state => state.authen.currentUser);
  return (
    <Tab.Navigator
      sceneContainerStyle={{
        backgroundColor: Ecolors.whiteColor,
      }} // initialRouteName={'TransactionScreen'}
      screenOptions={translateXOptionsScreen}
      tabBar={props => <FooterApp {...props} />}>
      {currentUser?.authorities?.includes('ROLE_SOURCE')
        ? ROLE_SOURCE.map((item, _) => {
            return (
              <Tab.Screen
                key={item.name}
                name={item.name}
                component={item.component}
              />
            );
          })
        : ROLE_BRANCH.map((item, _) => {
            return (
              <Tab.Screen
                key={item.name}
                name={item.name}
                component={item.component}
              />
            );
          })}
    </Tab.Navigator>
  );
}

export default React.memo(TabContainer);

import {Div, HeaderBack} from 'components';
import React from 'react';
import ListTransactions from './ListTransactions';
import RowTransactions from './RowTransactions';

function TransactionsFee() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(loadListTransactionFee({}));
  //   return () => {};
  // }, []);
  return (
    <Div flex={1}>
      <HeaderBack title={`transactionfee.TransactionFee`} />
      <RowTransactions />
      <ListTransactions />
    </Div>
  );
}

export default React.memo(TransactionsFee);

import {Div, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {loadListTransactionFee} from 'reducer/portal';
import {useAppSelector} from 'store/hooks';
import {convertNumber, toUnixTime} from 'utils';
import ItemCalendar from '../Dashboard/ItemCalendar';

const DD = new Date();

const RowTransactions = () => {
  const dispatch = useDispatch();
  const paramsTransactionFee = useAppSelector(
    state => state.portal.paramsTransactionFee,
  );

  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);

  useEffect(() => {
    const obj = {
      pagination: {
        currentPage: 1,
        itemsPerpage: 10,
        sort: {},
        totalItemCount: 10,
      },
      queries: {
        fromDate: toUnixTime(`${DD.getFullYear()}-${DD.getMonth() + 1}-${1}`),
        toDate: toUnixTime(
          `${DD.getFullYear()}-${DD.getMonth() + 1}-${DD.getDate()}`,
        ),
      },
    };
    dispatch(loadListTransactionFee(obj));
    return () => {};
  }, []);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      dispatch(
        loadListTransactionFee({
          pagination: paramsTransactionFee?.pagination || {},
          queries: {
            ...paramsTransactionFee?.queries,
            fromDate: toUnixTime(fromDate),
            toDate: toUnixTime(toDate),
          },
        }),
      );
    }
    return () => {};
  }, [fromDate, toDate]);

  return (
    <Div paddingHorizontal={16} marginTop={10}>
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <ItemCalendar
          title={'Từ ngày'}
          onChangeTime={(t: any) => setFromDate(t)}
          initValue={`${DD.getFullYear()}/${DD.getMonth() + 1}/${1}`}
        />
        <ItemCalendar
          title={'Đến ngày'}
          onChangeTime={(t: any) => setToDate(t)}
          initValue={`${DD.getFullYear()}/${DD.getMonth() + 1}/${DD.getDate()}`}
        />
      </Div>
      <Div
        width={343}
        height={48}
        backgroundColor={Ecolors.spaceColor}
        borderRadius={5}
        flexDirection={'row'}
        alignItems={'center'}
        paddingHorizontal={16}
        marginTop={16}
        justifyContent={'space-between'}>
        <Label size={14}>{`aumfee.totalfee`}</Label>
        <Label fontWeight={'bold'} size={14} multilanguage={false}>
          {convertNumber(paramsTransactionFee?.pagination?.totalValue || 0)}
        </Label>
      </Div>
    </Div>
  );
};
export default React.memo(RowTransactions);

import {useRoute} from '@react-navigation/core';
import {Div, HeaderBack, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {convertNumber, convertPercent, convertTimestamp} from 'utils';

const ItemRow = (p: {
  title: String;
  content: String;
  isEnd?: boolean;
  color?: string;
}) => {
  return (
    <>
      <Div
        paddingHorizontal={16}
        paddingVertical={9}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label color={Ecolors.grayColor} size={14}>
          {p.title}
        </Label>
        <Div flexDirection={'row'} alignItems={'center'}>
          {!!p.color && (
            <Div
              widthHeight={6}
              backgroundColor={p.color}
              borderRadius={10}
              marginRight={5}
            />
          )}
          <Label size={14} multilanguage={false} fontWeight={'600'}>
            {p.content}
          </Label>
        </Div>
      </Div>
      {!p.isEnd && (
        <Div
          marginHorizontal={16}
          width={340}
          height={0.5}
          backgroundColor={Ecolors.bordercolor}
        />
      )}
    </>
  );
};
function TransactionsFeeDetails() {
  const route = useRoute<any>();

  const {
    investmentNumber,
    investmentUserName,
    productCode,
    productProgramName,
    sessionTime,
    lockAmount,
    orderTypeName,
    sourceCode,
    sourceBranchCode,
    rm,
    code,
    percentFee,
    fee,
  } = route?.params?.data;
  return (
    <Div flex={1}>
      <HeaderBack
        type={2}
        title={`transactionfeedetals.transactionfeedetals`}
      />
      <Div height={10} />
      <ItemRow
        title={`transactionfeedetals.sotkgd`}
        content={investmentNumber}
      />
      <ItemRow
        title={`transactionfeedetals.tennhadautu`}
        content={investmentUserName}
      />
      <ItemRow title={`transactionfeedetals.quy`} content={productCode} />
      <ItemRow
        title={`transactionfeedetals.sanphamdautu`}
        content={productProgramName}
      />
      <ItemRow
        title={`transactionfeedetals.phiengiaodich`}
        content={convertTimestamp(sessionTime)}
      />
      <ItemRow
        title={`transactionfeedetals.grossamount`}
        content={convertNumber(lockAmount)}
      />
      <ItemRow
        title={`transactionfeedetals.loailenh`}
        content={orderTypeName}
      />
      <ItemRow
        title={`transactionfeedetals.nguondatlenh`}
        content={sourceCode}
      />
      <ItemRow
        title={`transactionfeedetals.chinhanhgiaodich`}
        content={sourceBranchCode}
      />
      <ItemRow title={`transactionfeedetals.rmgiaodich`} content={rm} />
      <ItemRow title={`transactionfeedetals.transactioncode`} content={code} />
      <ItemRow
        title={`transactionfeedetals.thoahonggioitheu`}
        content={convertPercent(percentFee)}
      />
      <ItemRow
        title={`transactionfeedetals.hoahonggioithieu`}
        isEnd={true}
        content={convertNumber(fee)}
      />
    </Div>
  );
}

export default React.memo(TransactionsFeeDetails);

import {Button, Div, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useState} from 'react';
import {FlatList, ScrollView} from 'react-native-gesture-handler';
import {useAppSelector} from 'store/hooks';
import {convertNumber} from 'utils';

const ItemRow = (p: {
  title: string;
  content: string | number;
  isEnd?: boolean;
  isBold?: boolean;
}) => {
  const fontWeight = p.isBold ? 'bold' : '500';
  return (
    <>
      <Div
        paddingHorizontal={20}
        paddingVertical={9}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label multilanguage={false} fontWeight={fontWeight}>
          {p.title}
        </Label>
        <Label fontWeight={fontWeight} multilanguage={false}>
          {convertNumber(p.content, true)}
        </Label>
      </Div>
      {!p.isEnd && (
        <Div
          marginHorizontal={16}
          width={301}
          height={0.5}
          backgroundColor={Ecolors.bordercolor}
        />
      )}
    </>
  );
};

const valuedata = [
  {
    content: 'Gross amount (buy)',
    id: 'totalSubscriptionAmount',
    header: 'numberTransactionSubscription',
  },
  {
    content: 'Gross amount (sell)',
    id: 'totalRedemAmount',
    header: 'numberTransactionRedem',
  },
  {
    content: 'Net amount',
    id: 'totalNetAmount',
    header: 'totalNetAmount',
  },
];
const ListAmount = () => {
  const [itemFocus, setItemFocus] = useState<any>(valuedata[0]);
  const listProductInfo = useAppSelector(state => state.portal.productInfo);
  // const amountDashboard = useAppSelector(state => state.portal.amountDashboard);

  const renderItem = ({item, index}) => {
    if (item.productCode == 'Transaction') return <></>;
    return (
      <ItemRow
        isEnd={index == listProductInfo?.length - 1}
        title={item.productCode}
        content={item[`${itemFocus.id}`] || 0}
      />
    );
  };

  const keyExtractor = (item, index) => {
    return `key${item.productId}`;
  };

  const ListHeaderComponent = () => {
    return (
      <ItemRow
        isBold={true}
        title={'Transactions'}
        content={
          listProductInfo[listProductInfo.length - 1]?.[itemFocus.header] || ''
        }
      />
    );
  };
  return (
    <Div>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <Div flexDirection={'row'} alignItems={'center'} paddingVertical={10}>
          {valuedata.map((item: any, index: number) => {
            const isFocus = itemFocus.id == item.id;
            return (
              <Button
                onPress={() => {
                  setItemFocus(item);
                }}
                key={index}
                marginRight={10}
                borderBottomWidth={2}
                paddingVertical={10}
                borderBottomColor={
                  isFocus ? Ecolors.mainColor : Ecolors.transparent
                }>
                <Label
                  size={14}
                  fontWeight={isFocus ? 'bold' : '500'}
                  multilanguage={false}>
                  {item.content}
                </Label>
              </Button>
            );
          })}
        </Div>
      </ScrollView>
      <Div borderColor={Ecolors.bordercolor} borderWidth={0.5} borderRadius={5}>
        <FlatList
          data={listProductInfo}
          extraData={[listProductInfo]}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListHeaderComponent={ListHeaderComponent}
        />
      </Div>
      <Div height={50} />
    </Div>
  );
};

export default React.memo(ListAmount);

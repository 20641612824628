import {useRoute} from '@react-navigation/core';
import {Div, HeaderBack, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {convertNumber, convertPercent} from 'utils';

const ItemRow = (p: {
  title: String;
  content: String;
  isEnd?: boolean;
  color?: string;
}) => {
  return (
    <>
      <Div
        paddingHorizontal={16}
        paddingVertical={9}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label color={Ecolors.grayColor} size={14}>
          {p.title}
        </Label>
        <Div flexDirection={'row'} alignItems={'center'}>
          {!!p.color && (
            <Div
              widthHeight={6}
              backgroundColor={p.color}
              borderRadius={10}
              marginRight={5}
            />
          )}
          <Label size={14} multilanguage={false} fontWeight={'600'}>
            {p.content}
          </Label>
        </Div>
      </Div>
      {!p.isEnd && (
        <Div
          marginHorizontal={16}
          width={340}
          height={0.5}
          backgroundColor={Ecolors.bordercolor}
        />
      )}
    </>
  );
};
function AUMFeeDetails() {
  const route = useRoute<any>();
  const {
    productCode,
    dateStats,
    fundVolume,
    navValue,
    aum,
    rmCode,
    rmName,
    branchCode,
    branchName,
    percentFee,
    fee,
  } = route?.params?.data;
  return (
    <Div flex={1}>
      <HeaderBack type={2} title={`aumfeedetails.aumfeedetails`} />
      <Div height={10} />
      <ItemRow title={`aumfeedetails.funds`} content={productCode} />
      <ItemRow title={`aumfeedetails.date`} content={dateStats} />
      <ItemRow
        title={`aumfeedetails.volume`}
        content={convertNumber(fundVolume, true)}
      />
      <ItemRow title={`aumfeedetails.nav`} content={convertNumber(navValue)} />
      <ItemRow title={`aumfeedetails.aum`} content={convertNumber(aum, true)} />
      <ItemRow title={`aumfeedetails.rm`} content={`${rmCode} - ${rmName}`} />
      <ItemRow title={`aumfeedetails.branchcode`} content={branchCode} />
      <ItemRow title={`aumfeedetails.branchname`} content={branchName} />
      <ItemRow
        title={`aumfeedetails.tphiduytrikhachhang`}
        content={convertPercent(percentFee)}
      />
      <ItemRow
        title={`aumfeedetails.phiduytrikhachhang`}
        content={convertNumber(fee)}
        isEnd={true}
      />
    </Div>
  );
}

export default React.memo(AUMFeeDetails);

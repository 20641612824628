import AUMFeeDetails from './AUMFee/AUMFeeDetails';
import InvestorDetails from './Investor/InvestorDetails';
import TransactionsFeeDetails from './TransactionsFee/TransactionsFeeDetails';
const screens: any = {
  InvestorDetails,
  AUMFeeDetails,
  TransactionsFeeDetails,
};

const stackPoprtal = Object.keys(screens).map(item => {
  return {
    name: item,
    component: screens?.[item],
  };
});

export {stackPoprtal};

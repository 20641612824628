import {Div, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {useAppSelector} from 'store/hooks';
import {convertNumber} from 'utils';
const AmountRow = () => {
  const amountDashboard = useAppSelector(state => state.portal.amountDashboard);
  const {totalRedemAmount, totalSubscriptionAmount, totalNetAmount} =
    amountDashboard;
  return (
    <Div>
      <Div
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Div
          width={164}
          height={60}
          borderRadius={5}
          backgroundColor={Ecolors.spaceLinkColor}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Label size={14}>{`dashboard.grossamountbuy`}</Label>
          <Label multilanguage={false} fontWeight={'bold'}>
            {convertNumber(totalSubscriptionAmount || 0, true)}
          </Label>
        </Div>
        <Div
          width={164}
          height={60}
          borderRadius={5}
          backgroundColor={Ecolors.spaceColor}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}>
          <Label size={14}>{`dashboard.grossamountsell`}</Label>
          <Label multilanguage={false} fontWeight={'bold'}>
            {convertNumber(totalRedemAmount || 0, true)}
          </Label>
        </Div>
      </Div>
      <Div
        width={343}
        height={60}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderRadius={5}
        paddingHorizontal={17}
        marginTop={10}
        backgroundColor={Ecolors.spaceGreenColor}>
        <Label size={14}>{`dashboard.netamount`}</Label>
        <Label
          multilanguage={false}
          color={totalNetAmount < 0 ? Ecolors.redColor : Ecolors.greenColor}
          fontWeight={'bold'}>
          {`${totalNetAmount < 0 ? '-' : '+'}${convertNumber(
            Math.abs(totalNetAmount) || 0,
            true,
          )}`}
        </Label>
      </Div>
    </Div>
  );
};

export default React.memo(AmountRow);

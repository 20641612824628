import {useRoute} from '@react-navigation/native';
import {Div, HeaderBack, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadInvestmentDetails} from 'reducer/portal';
import {useAppSelector} from 'store/hooks';
import {convertTimestamp} from 'utils';

const convertStatusInvestor = (
  statusInvestor: string,
  isColor: Boolean,
  loadingInvestmentDetails: boolean,
) => {
  if (!isColor) {
    if (loadingInvestmentDetails) {
      return '...';
    }
    switch (statusInvestor) {
      case 'INVESTMENT_PROFILE_PENDING':
        return 'Chờ duyệt';
      case 'INVESTMENT_PROFILE_APPROVE':
        return 'Đã duyệt';
      case 'INVESTMENT_PROFILE_ACCEPT':
        return 'VSD';
      default:
        return '...';
    }
  }
  switch (statusInvestor) {
    case 'INVESTMENT_PROFILE_PENDING':
      return Ecolors.redColor;
    case 'INVESTMENT_PROFILE_APPROVE':
      return Ecolors.greenColor;
    case 'INVESTMENT_PROFILE_ACCEPT':
      return Ecolors.greenColor;
    default:
      return Ecolors.redColor;
  }
};

const ItemRow = (p: {
  title: String;
  content: String;
  isEnd?: boolean;
  color?: string;
}) => {
  return (
    <>
      <Div
        paddingHorizontal={16}
        paddingVertical={9}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Label color={Ecolors.grayColor} size={14}>
          {p.title}
        </Label>
        <Div flexDirection={'row'} alignItems={'center'}>
          {!!p.color && (
            <Div
              widthHeight={6}
              backgroundColor={p.color}
              borderRadius={10}
              marginRight={5}
            />
          )}
          <Label size={14} multilanguage={false} fontWeight={'600'}>
            {p.content}
          </Label>
        </Div>
      </Div>
      {!p.isEnd && (
        <Div
          marginHorizontal={16}
          width={340}
          height={0.5}
          backgroundColor={Ecolors.bordercolor}
        />
      )}
    </>
  );
};

function InvestorDetails() {
  const route = useRoute<any>();
  const dispatch = useDispatch();
  const investmentDetails = useAppSelector(
    state => state.portal.investmentDetails,
  );
  const loadingInvestmentDetails = useAppSelector(
    state => state.portal.loadingInvestmentDetails,
  );
  const {investorPersonalInfo} = investmentDetails;
  const {
    accountNo,
    phone,
    email,
    investorName,
    sourceCode,
    dateOfCreateAccount,
    rmCode,
    rmName,
    idCard,
  } = route.params?.data;
  useEffect(() => {
    dispatch(
      loadInvestmentDetails({
        idNoOrInvestmentNumber: route.params?.data?.idCard || '1',
      }),
    );
  }, []);

  return (
    <Div screen={true}>
      <HeaderBack type={2} title={'investordetails.chitietnhadautu'} />
      <Div height={10} />
      <ItemRow title={`investordetails.sotaikhoan`} content={accountNo} />
      <ItemRow title={`investordetails.hoten`} content={investorName} />
      <ItemRow title={`investordetails.sodienthoai`} content={phone} />
      <ItemRow title={`investordetails.email`} content={email} />
      <ItemRow
        title={`investordetails.ngaymotaikhoan`}
        content={convertTimestamp(dateOfCreateAccount)}
      />
      <ItemRow title={`investordetails.rm`} content={`${rmCode} - ${rmName}`} />
      <ItemRow
        title={`investordetails.trangthai`}
        color={convertStatusInvestor(
          investorPersonalInfo?.statusInvestor,
          true,
          loadingInvestmentDetails,
        )}
        content={convertStatusInvestor(
          investorPersonalInfo?.statusInvestor,
          false,
          loadingInvestmentDetails,
        )}
        isEnd={true}
      />
    </Div>
  );
}

export default React.memo(InvestorDetails);

import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {toastRef} from 'components/Toast';
import ToastRoot from 'components/Toast/ToastRoot';
import React, {useEffect} from 'react';
import {enableFreeze, enableScreens} from 'react-native-screens';
import {useDispatch} from 'react-redux';
import {getInfo} from 'reducer/authen';
import {changeLanguage} from 'reducer/languages/reducer';
import {componentAction} from 'screens';
import {navigationRef} from 'services/navigation';
import {useAppSelector} from 'store/hooks';
import TabContainer from './TabContainer';
import {
  screenOptions,
  translateXOptionsScreen,
  translateYModal,
} from './optionsNavigate';
// import {handleNotification} from 'services';
import {Div, LoadingIndicator} from 'components';
import Alert, {alertRef} from 'components/Alert';
import AlertCp from 'components/BottomSheetDialog/AlertCp';
import {Ecolors, Icons} from 'constant';
import {Linking, StatusBar, StyleSheet} from 'react-native';
import {getLoadingContainer} from 'reducer/portal';
import {stackPoprtal} from 'screens/Portal';
import {detectLinkData} from 'utils';
import {setStoreData} from 'utils/storage';

enableScreens(false);
enableFreeze(true);

const Stack = createStackNavigator();

function Navi() {
  const {statusScreen} = useAppSelector(state => state.authen);
  const activeLanguage = useAppSelector(
    state => state.languages.activeLanguage,
  );
  const currentUser = useAppSelector<any>(state => state.authen.currentUser);
  const loading = useAppSelector<boolean>(state => getLoadingContainer(state));
  const dispatch = useDispatch();
  React.useEffect(() => {
    bindDataUrl();
    return () => {};
  }, []);

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        setStoreData('email', obj['email'] || '');
        setStoreData('phone', obj['phone'] || '');
        setStoreData('refCode', obj['refCode'] || '');
        setStoreData('agentCode', obj['agentCode'] || '');
        setStoreData('refKey', obj['refKey'] || '');
        // Object.keys(obj).map((e: string) => {
        //   setStoreData(e, obj[e] || '');
        // });
      })
      .catch((err: any) => {})
      .finally(async () => {
        const r: any = await dispatch(getInfo({}));
        if (r.payload.status == 401) {
          Alert.showError({
            content: 'Đăng nhập thất bại!',
            multilanguage: false,
          });
        }
      });
  };
  useEffect(() => {
    // dispatch(changeLanguage(activeLanguage));
    dispatch(
      changeLanguage({
        code: 'vi',
        name: 'Vie',
        icons: Icons.vietnam,
      }),
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (statusScreen == 'main') {
      getInfoAndEKYC();
    }
    return () => {};
  }, [statusScreen]);

  const getInfoAndEKYC = async () => {
    const r: any = await dispatch(getInfo({}));

    // if (!!r) {
    //   gotoEKYC(r.payload);
    // }
  };

  return (
    <NavigationContainer independent={true} ref={navigationRef}>
      {loading && (
        <Div
          style={StyleSheet.absoluteFillObject}
          position={'absolute'}
          elevation={999}
          alignItems={'center'}
          justifyContent={'center'}
          backgroundColor={Ecolors.transparentLoading}
          zIndex={999}>
          <LoadingIndicator color={Ecolors.mainColor} size={12} />
        </Div>
      )}
      <StatusBar
        backgroundColor={Ecolors.transparent}
        barStyle={statusScreen == 'main' ? 'light-content' : 'light-content'}
        hidden={false}
      />
      <ToastRoot ref={toastRef} />
      <AlertCp ref={alertRef} />
      <Stack.Navigator
        screenOptions={screenOptions}
        initialRouteName={'LoginScreen'}>
        {/* {switchStatusStack()}
         */}
        <Stack.Screen
          options={translateXOptionsScreen}
          key={'TabContainer'}
          name={'TabContainer'}
          component={TabContainer}
        />
        {stackPoprtal.map((item, _) => (
          <Stack.Screen
            options={translateXOptionsScreen}
            key={item.name}
            name={item.name}
            component={item.component}
          />
        ))}
        {componentAction.map((item: any, index: number) => {
          return (
            <Stack.Screen
              options={item.options || translateYModal}
              key={item.name}
              name={item.name}
              component={item.component}
            />
          );
        })}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default React.memo(Navi);

import {useFocusEffect} from '@react-navigation/native';
import {Button, Div, ImageView, InputItem} from 'components';
import {Ecolors, Icons} from 'constant';
import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {loadListInvestment} from 'reducer/portal';

const SearchInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const [textFilter, setTextFilter] = useState<string>('');

  useFocusEffect(
    React.useCallback(() => {
      if (inputRef.current) {
        inputRef.current.clear();
        setTextFilter('');
      }
      return () => {};
    }, []),
  );

  const onHandleChange = () => {
    dispatch(
      loadListInvestment({
        queries: {
          investorName: textFilter,
        },
      }),
    );
  };

  return (
    <Div>
      <InputItem
        inputRef={inputRef}
        marginHorizontal={16}
        value={textFilter}
        onHandleChange={onHandleChange}
        onChangeText={(t: string) => setTextFilter(t)}
        placeholder={'Nhập họ tên'}
        marginTop={12}
        keyboardType={'default'}
        renderButtonRight={() => {
          return (
            <Button
              alignItems={'center'}
              onPress={() => {
                onHandleChange();
              }}
              justifyContent={'center'}>
              <ImageView
                source={Icons.search}
                widthHeight={18}
                resizeMode={'contain'}
                tintColor={Ecolors.grayColor}
              />
            </Button>
          );
        }}
      />
      {/* <Div
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        height={48}
        borderRadius={5}
        marginTop={12}
        borderWidth={0.8}
        overflow={'hidden'}
        borderColor={Ecolors.bordercolor}
        backgroundColor={Ecolors.whiteColor}
        marginHorizontal={16}>
       
        <Input
          flex={1}
          margin={0}
          paddingHorizontal={16}
          height={'100%'}
          width={'100%'}
          inputRef={inputRef}
          value={textFilter}
          autoCorrect={false}
          onChangeText={(t: string) => setTextFilter(t)}
          returnKeyType={'done'}
          placeholder={'Nhập thông tin tìm kiếm'}
          placeholderTextColor={Ecolors.grayColor}
          keyboardType={'default'}
        />
          <Button
                widthHeight={48}
                alignItems={'center'}
                justifyContent={'center'}>
                <ImageView
                  source={Icons.search}
                  widthHeight={18}
                  resizeMode={'contain'}
                  tintColor={Ecolors.grayColor}
                />
              </Button>
      </Div> */}
    </Div>
  );
};

export default React.memo(SearchInput);

import {Div, HeaderBack} from 'components';
import React from 'react';
import ListAUM from './ListAUM';
import RowAUM from './RowAUM';

function AUMFee() {
  return (
    <Div flex={1}>
      <HeaderBack title={`aumfee.AUMFee`} />
      <RowAUM />
      <ListAUM />
    </Div>
  );
}

export default React.memo(AUMFee);

import {Div, Label} from 'components';
import {Ecolors} from 'constant';
import React from 'react';
import {
  getDataBuyChartPortal,
  getDataNetChartPortal,
  getDataSellChartPortal,
} from 'reducer/portal';
import {useAppSelector} from 'store/hooks';
import {convertNumber, widthScale, widthScreen} from 'utils';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryTheme,
} from 'victory';

const Amount = (p: {color: string; title: string}) => {
  return (
    <Div flexDirection="row" alignItems="center" justifyContent="center">
      <Div widthHeight={10} marginRight={5} backgroundColor={p.color} />
      <Label size={12} multilanguage={false}>
        {p.title}
      </Label>
    </Div>
  );
};

const barWidth = widthScale(7);
const ChartDashboard = () => {
  const dataBuy = useAppSelector(state => getDataBuyChartPortal(state));
  const dataSell = useAppSelector(state => getDataSellChartPortal(state));
  const dataNet = useAppSelector(state => getDataNetChartPortal(state));

  return (
    <Div width={344} marginBottom={20}>
      <Label
        marginVertical={10}
        multilanguage={false}
        size={14}>{`Unit: ${convertNumber(1000000000)}`}</Label>

      <VictoryChart
        width={widthScreen}
        height={widthScale(260)}
        padding={{
          right: widthScale(10),
          top: widthScale(0),
          left: widthScale(30),
          bottom: widthScale(20),
        }}
        theme={VictoryTheme.material}
        domainPadding={{y: widthScale(20), x: widthScale(20)}}>
        <VictoryAxis
          style={{
            axis: {stroke: Ecolors.grayColor},
            tickLabels: {
              fontSize: widthScale(8),
              color: Ecolors.textColor,
              fontWeight: '400',
            },
          }}
          offsetY={widthScale(20)}
        />
        <VictoryAxis
          dependentAxis
          standalone={true}
          style={{
            axis: {stroke: Ecolors.grayColor},
            tickLabels: {
              fontSize: widthScale(8),
              color: Ecolors.textColor,
              fontWeight: '400',
            },
          }}
          offsetX={widthScale(30)}
        />
        <VictoryGroup offset={20} colorScale={'qualitative'}>
          {!!dataBuy?.length && (
            <VictoryBar
              barWidth={({index}) => barWidth}
              data={dataBuy}
              style={{
                data: {
                  fill: Ecolors.green,
                },
              }}
            />
          )}
          {!!dataNet?.length && (
            <VictoryBar
              barWidth={({index}) => barWidth}
              data={dataNet}
              style={{
                data: {
                  fill: Ecolors.blue,
                },
              }}
            />
          )}
          {!!dataSell?.length && (
            <VictoryBar
              barWidth={({index}) => barWidth}
              data={dataSell}
              style={{
                data: {
                  fill: Ecolors.red,
                },
              }}
            />
          )}
        </VictoryGroup>
      </VictoryChart>
      <Div
        flexDirection="row"
        alignItems="center"
        marginTop={10}
        justifyContent="space-between">
        <Amount color={Ecolors.green} title={'Sub Amount'} />
        <Amount color={Ecolors.blue} title={'Net Amount'} />
        <Amount color={Ecolors.red} title={'Redem Amount'} />
      </Div>
    </Div>
  );
};
export default React.memo(ChartDashboard);

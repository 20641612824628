import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {navigate} from 'services';
import {widthScreen} from 'utils';

interface IPropsFooterApp {
  state: {
    routes: Array<any>;
    index: number;
  };
}

interface IPropsItemButotn {
  isFocus: boolean;
  index: number;
  title: string;
  icon: any;
  onPress: () => void;
}

function ItemButton(props: IPropsItemButotn) {
  return (
    <Button
      onPress={() => {
        if (props.isFocus) {
          return;
        }
        props.onPress && props.onPress();
      }}
      style={{
        width: widthScreen / 3.5,
      }}
      alignItems={'center'}
      justifyContent={'center'}>
      <ImageView
        heightWidth={22}
        tintColor={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
        source={props.icon}
        resizeMode={'contain'}
      />
      <Label
        marginTop={3}
        marginBottom={4}
        size={11}
        color={props.isFocus ? Ecolors.mainColor : Ecolors.grayColor}>
        {props.title}
      </Label>
    </Button>
  );
}

function FooterApp(props: IPropsFooterApp) {
  const insest = useSafeAreaInsets();

  const ObjectAction: any = {
    Dashboard: {
      title: `footerapp.dashboard`,
      icon: Icons.dashboard,
      onPress: () => {
        navigate('Dashboard', {}, true);
      },
    },
    Investor: {
      title: `footerapp.investor`,
      icon: Icons.investor,
      onPress: () => {
        navigate('Investor', {}, true);
      },
    },
    ManagementFee: {
      title: `footerapp.managementfee`,
      icon: Icons.managementfee,
      onPress: () => {
        navigate('ManagementFee', {}, true);
      },
    },
    AUMFee: {
      title: `footerapp.AUMFee`,
      icon: Icons.aumfee,
      onPress: () => {
        navigate('AUMFee', {}, true);
      },
    },
    TransactionsFee: {
      title: `footerapp.TransactionFee`,
      icon: Icons.transactionfee,
      onPress: () => {
        navigate('TransactionsFee', {}, true);
      },
    },
  };
  return (
    <Div
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-evenly'}
      backgroundColor={Ecolors.whiteColor}
      borderTopColor={Ecolors.grayColor}
      borderTopWidth={0.5}
      style={{
        paddingBottom: insest.bottom,
      }}
      paddingTop={10}>
      {props.state.routes?.map((item: any, index: number) => {
        const data = ObjectAction[item.name];
        return (
          <ItemButton
            key={index}
            onPress={() => data.onPress()}
            isFocus={index == props.state.index}
            index={index}
            title={data.title}
            icon={data.icon}
          />
        );
      })}
    </Div>
  );
}

export default React.memo(FooterApp);

export enum urlApp {
  // test -------------------------------------------
  APIURL = 'https://apis.fplatform.fincorp.vn/portal/v1/', //test//
  // DomainName = 'https://pvcom.mobile', //
  // DomainName = 'https://fplatform.mobile', //

  // // //uat -------------------------------------------
  // APIURL = 'https://oapis.fplatform-uat.fincorp.vn/portal/v1/', //uat//
  // DomainName = 'https://pvcom.mobile', //

  // live -------------------------------------------
  // APIURL = 'https://oapis.fplatform-live.fincorp.vn/portal/v1/', //live
  // https://oapis.fplatform-live.fincorp.vn/portal/v1/api/auth/token
  // DomainName = 'https://pvcom.mobile', //
}

import {Div, HeaderBack} from 'components';
import React, {useEffect, useState} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {useDispatch} from 'react-redux';
import {loadAmountDashboard} from 'reducer/portal';
import {joinObjectNewCalendar} from 'utils';
import AmountRow from './AmountRow';
import ChartDashboard from './ChartDashboard';
import ItemCalendar from './ItemCalendar';
import ListAmount from './ListAmount';

const DD = new Date();

function Dashboard() {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);

  const loadData = (from, to) => {
    dispatch(
      loadAmountDashboard({
        fromDate: from,
        toDate: to,
        sourceCode: '',
        // fromDate: '20080101',
        // sourceCode: '',
        // toDate: '20221201',
      }),
    );
  };

  // useEffect(() => {
  //   const str = `${DD.getFullYear()}${DD.getMonth() + 1}${1}`;
  //   const str1 = `${DD.getFullYear()}${DD.getMonth() + 1}${DD.getDate()}`;
  //   loadData(str, str1);
  //   return () => {};
  // }, []);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      loadData(joinObjectNewCalendar(fromDate), joinObjectNewCalendar(toDate));
    }
    return () => {};
  }, [fromDate, toDate]);
  return (
    <Div flex={1}>
      <HeaderBack title={`dashboard.dashboard`} />
      <ScrollView>
        <Div paddingHorizontal={16} paddingTop={18}>
          <Div
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <ItemCalendar
              title={'Từ ngày'}
              onChangeTime={(t: any) => {
                setFromDate(t);
              }}
              initValue={`${DD.getFullYear()}/${DD.getMonth() + 1}/${1}`}
            />
            <ItemCalendar
              title={'Đến ngày'}
              onChangeTime={(t: any) => {
                setToDate(t);
              }}
              initValue={`${DD.getFullYear()}/${
                DD.getMonth() + 1
              }/${DD.getDate()}`}
            />
          </Div>
          <ChartDashboard />
          <AmountRow />
          <ListAmount />
        </Div>
      </ScrollView>
    </Div>
  );
}

export default React.memo(Dashboard);

import {Button, Div, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React from 'react';
import {FlatList, RefreshControl} from 'react-native-gesture-handler';
import {navigate} from 'services';
import {useAppSelector} from 'store/hooks';

const ItemList = (p: {
  data: any;
  nonForward?: boolean;
  background?: boolean;
}) => {
  const fontWeight = p.background ? 'bold' : '500';
  return (
    <Button
      onPress={() => {
        if (!p.nonForward) {
          navigate('AUMFeeDetails', {
            data: p.data,
          });
        }
      }}
      backgroundColor={p.background ? Ecolors.spaceColor : Ecolors.whiteColor}
      borderRadius={p.background ? 5 : 0}
      paddingHorizontal={10}
      height={48}
      marginHorizontal={16}
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start">
      <Div flexDirection="row" alignItems="center" width={144}>
        <Label size={14} fontWeight={fontWeight} multilanguage={false}>
          {p.data.productCode}
        </Label>
      </Div>
      <Div flexDirection="row" alignItems="center" width={150}>
        <Label
          numberOfLines={1}
          size={14}
          fontWeight={fontWeight}
          multilanguage={false}>
          {p.data.dateStats}
        </Label>
      </Div>
      {!p.nonForward ? (
        <ImageView
          widthHeight={14}
          source={Icons.forward}
          resizeMode={'contain'}
        />
      ) : (
        <Div width={34}></Div>
      )}
    </Button>
  );
};

const ListAUM = () => {
  const loadingListAUMFee = useAppSelector(
    state => state.portal.loadingListAUMFee,
  );
  const listAUMFee = useAppSelector(state => state.portal.listAUMFee);

  const renderItem = ({item, index}) => {
    return <ItemList data={item} />;
  };
  const ListHeaderComponent = ({item, index}) => {
    return <Div />;
  };
  const ItemSeparatorComponent = () => {
    return (
      <Div
        marginHorizontal={16}
        height={1}
        opacity={0.2}
        backgroundColor={`#231F20`}
      />
    );
  };
  const ListFooterComponent = () => {
    return <Div />;
  };
  const ListEmptyComponent = () => {
    return <Div />;
  };

  const keyExtractor = (item, index) => {
    return `key${index}`;
  };

  const onRefresh = () => {};
  const onLoadMore = () => {};

  return (
    <>
      <Div height={10} />
      <ItemList
        background={true}
        nonForward={true}
        data={{
          productCode: 'Funds',
          dateStats: 'Date',
        }}
      />
      <Div height={10} />
      <FlatList
        data={listAUMFee}
        extraData={listAUMFee}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps={'handled'}
        refreshControl={
          <RefreshControl
            refreshing={loadingListAUMFee}
            onRefresh={onRefresh}
            tintColor={Ecolors.mainColor}
          />
        }
        onEndReached={onLoadMore}
        renderItem={renderItem}
        ListHeaderComponent={ListHeaderComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListEmptyComponent={ListEmptyComponent}
        ListFooterComponent={ListFooterComponent}
        onEndReachedThreshold={0.4}
      />
    </>
  );
};
export default React.memo(ListAUM);
